var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "canvasBox", staticClass: "customScrollbar-box" }, [
    _c("div", { staticClass: "wrap" }, [
      _c("canvas", {
        staticClass: "fl",
        class: { eraserStyle: _vm.cursorStyle },
        attrs: {
          id: "canvas-1",
          width: _vm.canvasWidth,
          height: _vm.canvasHeight,
        },
        on: {
          mousedown: function ($event) {
            return _vm.handleMouseDown($event)
          },
          mousemove: function ($event) {
            return _vm.handleMouseMove($event)
          },
          mouseup: function ($event) {
            return _vm.handleMouseUp($event)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowText,
            expression: "isShowText",
          },
        ],
        staticClass: "text-input",
        style: { top: _vm.textInputTop + "px", left: _vm.textInputLeft + "px" },
        attrs: { placeholder: "请输入文字" },
        on: {
          change: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.textChange($event)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }