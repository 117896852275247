<template>
  <div class="customScrollbar-box" ref="canvasBox">
    <div class="wrap">
      <canvas
        id="canvas-1"
        class="fl"
        :width="canvasWidth"
        :height="canvasHeight"
        @mousedown="handleMouseDown($event)"
        @mousemove="handleMouseMove($event)"
        @mouseup="handleMouseUp($event)"
        :class="{ eraserStyle: cursorStyle }"
      >
      </canvas>
      <input
        placeholder="请输入文字"
        class="text-input"
        :style="{ top: textInputTop + 'px', left: textInputLeft + 'px' }"
        v-show="isShowText"
        @change.enter="textChange($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayColorIndex: {
      type: Number,
      require: false,
      default: 0,
    },
    cursorStyle: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      textInputTop: 0,
      textInputLeft: 0,
      isShowText: false,

      canvasHeight: null,
      canvasWidth: null,
      // 配置参数
      lineWidth: 3, // 画线粗细
      drawColor: "#CD4C3F", // 基础颜色
      // shadowBlur: 3,
      fontSize: 16, // 文字字体大小
      textContent: "", // 文字内容
      shapeType: 0, // 图形 默认正方形

      nextDrawAry: [],
      middleAry: [],
      preDrawAry: [],

      clickDrag: [],
      lineX: [],
      lineY: [],
      eraserX: [],
      eraserY: [],
      rect: {},
      storage: {},
      triangle: {},
      trianglePoint: {},

      lineArr: [],
      circleArr: [],
      rectArr: [],

      textArr: [],
      eraserArr: [],
      triangleArr: [],
      eraserWidth: 50,

      // isCanvasShow: true,
      drawStatus: 0,
      // 画笔状态 0不可用 1矩形 2圆形  3画线 4文字 5三角形 6橡皮擦

      lineArrSocket: {},
      rectArrSocket: {},
      circleArrSocket: {},
      eraserArrSocket: {},
      triangleArrSocket: {},
      textArrSocket: {},
    };
  },
  watch: {
    displayColorIndex(value) {
      setTimeout(() => {
        this.drawRectGround(value);
      }, 50);
    },
    drawStatus(value) {
      this.isShowText = value === 4 ? true : false;
    },
    lineArrSocket: {
      handler(value) {
        // console.log("画线", value);
        const data = {
          clickType: 1000401,
          data: {
            arr: value,
            width: this.canvasWidth,
            height: this.canvasHeight,
          },
          text: "whiteBoard页面socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },

    rectArrSocket: {
      handler(value) {
        // console.log("画矩形", value);
        const data = {
          clickType: 1000402,
          data: { value },
          text: "whiteBoard页面socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },

    circleArrSocket: {
      handler(value) {
        // console.log("画圆形", value);
        const data = {
          clickType: 1000403,
          data: { value },
          text: "whiteBoard页面socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },

    textArrSocket: {
      handler(value) {
        // console.log("画文字", value);
        const data = {
          clickType: 1000404,
          data: { value },
          text: "whiteBoard页面socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },

    triangleArrSocket: {
      handler(value) {
        // console.log("画三角形", value);
        const data = {
          clickType: 1000405,
          data: { value },
          text: "whiteBoard页面socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },

    eraserArrSocket: {
      handler(value) {
        // console.log("橡皮擦", value);
        const data = {
          clickType: 1000408,
          data: { value },
          text: "whiteBoard页面socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      const canvasBox = this.$refs.canvasBox;
      this.canvasHeight = canvasBox.getBoundingClientRect().height;
      this.canvasWidth = canvasBox.getBoundingClientRect().width;
      // 绑定canvas
      const canvas = document.querySelector("#canvas-1");
      this.ctx = canvas.getContext("2d");
      this.initCanvas();
      // scratch游戏
      setTimeout(() => {
        this.drawRectGround(this.displayColorIndex);
      }, 50);
    });

    // this.initDrawStyle();
    // 监听画线事件
    this.$bus.$on("showDrawLine", ({ arr, width, height }) => {
      // console.log(value);
      arr.value.forEach((item) => {
        item.x = item.x.map((ele) =>
          parseInt((ele / width) * this.canvasWidth)
        );
        item.y = item.y.map((ele) =>
          parseInt((ele / height) * this.canvasHeight)
        );
      });
      this.lineArr = arr.value;
      this.whenMouseDown();
      this.redrawAll();
      this.prevAndnext();
    });
    // 监听画矩形事件
    this.$bus.$on("showDrawRect", ({ value }) => {
      // console.log(value);
      this.rectArr = value;
      this.whenMouseDown();
      this.redrawAll();
      this.prevAndnext();
    });
    // 监听画圆形事件
    this.$bus.$on("showDrawCircle", ({ value }) => {
      this.circleArr = value;
      this.whenMouseDown();
      this.redrawAll();
      this.prevAndnext();
    });
    // 监听文字事件
    this.$bus.$on("showDrawText", ({ value }) => {
      this.textArr = value;
      this.whenMouseDown();
      this.redrawAll();
      this.prevAndnext();
    });

    // 监听三角形事件
    this.$bus.$on("showDrawTriangle", ({ value }) => {
      this.triangleArr = value;
      this.whenMouseDown();
      this.redrawAll();
      this.prevAndnext();
    });

    // 监听橡皮擦事件
    this.$bus.$on("showEraser", ({ value }) => {
      this.eraserArr = value;
      this.whenMouseDown();
      this.redrawAll();
      this.prevAndnext();
    });

    // 监听控制前进后退清空事件
    this.$bus.$on("showControlCanvas", (value) => {
      this.controlCanvas(value);
      console.log(value);
    });

    // 前进  后退   清空
    this.$bus.$on("controlCanvas", (val) => {
      this.controlCanvas(val);
    });
    // // 鼠标点击
    // this.$bus.$on("canvasON", () => {
    //   this.drawStatus = 0;
    // });
    // 选择画线
    this.$bus.$on("chooseLine", () => {
      this.drawStatus = 3;
      this.cursorStyle = false;
    });
    // 选择画图形
    this.$bus.$on("chooseShape", (index) => {
      if (index === 0) {
        this.drawStatus = 1;
      } else if (index === 1) {
        this.drawStatus = 2;
      } else {
        // 三角形
        this.drawStatus = 5;
      }
      this.cursorStyle = false;
    });
    // 选择添加文字
    this.$bus.$on("chooseText", (val) => {
      this.textContent = "";
      this.drawStatus = 4;
      this.isShowText = true;
      this.fontSize = val;
      this.cursorStyle = false;
    });
    //选择橡皮擦
    this.$bus.$on("chooseEraser", () => {
      this.drawStatus = 6;
      this.cursorStyle = true;
    });
    // 选择颜色
    this.$bus.$on("chooseDrawColor", (val) => {
      this.drawColor = val;
    });
    // 选择线条粗细
    this.$bus.$on("chooseLineWidth", (val) => {
      this.lineWidth = val;
      this.drawStatus = 3;
    });
  },
  beforeDestroy() {
    this.$bus.$off("showDrawLine");
    this.$bus.$off("showDrawRect");
    this.$bus.$off("showDrawCircle");
    this.$bus.$off("showDrawText");
    this.$bus.$off("showDrawTriangle");
    this.$bus.$off("showEraser");
    this.$bus.$off("showControlCanvas");
    this.$bus.$off("controlCanvas");
    // this.$bus.$off("canvasON");
    this.$bus.$off("chooseLine");
    this.$bus.$off("chooseShape");
    this.$bus.$off("chooseText");
    this.$bus.$off("chooseEraser");
    this.$bus.$off("chooseDrawColor");
    this.$bus.$off("chooseLineWidth");
  },
  methods: {
    drawRectGround(index) {
      if (!index) {
        return;
      }
      // this.initCanvas();
      if (index === 1) {
        // 黄色
        this.ctx.fillStyle = "#F4D737";
      } else if (index === 2) {
        this.ctx.fillStyle = "#224E96";
      } else if (index === 3) {
        this.ctx.fillStyle = "#CD4C3F";
      }
      console.log(index, "+++++++++++++");
      this.ctx.fillRect(0, 0, this.canvasWidth * 0.9, this.canvasHeight * 0.92);
    },
    // 鼠标按下
    whenMouseDown() {
      const preData = this.ctx.getImageData(
        0,
        0,
        this.canvasWidth,
        this.canvasHeight
      );
      // 当前绘图表面进栈
      this.preDrawAry.push(preData);
    },
    // 上一步下一步监听
    prevAndnext() {
      const preData = this.ctx.getImageData(
        0,
        0,
        this.canvasWidth,
        this.canvasHeight
      );
      if (!this.nextDrawAry.length) {
        // 当前绘图表面进栈
        // console.log("无下一张图");
        this.middleAry.push(preData);
      } else {
        // console.log("有下一张图");
        this.middleAry = [];
        this.middleAry = this.middleAry.concat(this.preDrawAry);
        this.middleAry.push(preData);
        this.nextDrawAry = [];
      }
    },
    // 初始化canvas画布
    initCanvas() {
      this.clear();
      // 空绘图表面进栈
      const preData = this.ctx.getImageData(
        0,
        0,
        this.canvasWidth,
        this.canvasHeight
      );
      this.middleAry.push(preData);
    },

    // 鼠标按下
    handleMouseDown(e, isFromSocket = false) {
      const preData = this.ctx.getImageData(
        0,
        0,
        this.canvasWidth,
        this.canvasHeight
      );
      // 当前绘图表面进栈
      this.imageData = preData; //方便交互
      this.preDrawAry.push(preData);

      //   let touch = t.touch ? e.touches[0] : e;
      //记录点击的坐标点
      this.lock = true;
      let _x = e.offsetX;
      let _y = e.offsetY;
      if (this.drawStatus === 1) {
        // 矩形
        this.rect.x = _x;
        this.rect.y = _y;
      } else if (this.drawStatus === 2) {
        //圆形
        this.storage.x = _x;
        this.storage.y = _y;
      } else if (this.drawStatus === 3) {
        this.movePoint(_x, _y);
        this.drawPoint(
          this.lineX,
          this.lineY,
          this.clickDrag,
          this.lineWidth,
          this.drawColor
        );
      } else if (this.drawStatus === 4) {
        this.textInputTop = _y;
        this.textInputLeft = _x;
        // 画一个textarea
        this.ctx.font = `${this.fontSize}px bold 黑体`;
        // 设置颜色
        this.ctx.fillStyle = this.drawColor;
        // 设置水平对齐方式
        this.ctx.textAlign = "center";
        // 设置垂直对齐方式
        this.ctx.textBaseline = "middle";
        // 绘制文字（参数：要写的字，x坐标，y坐标）
        this.ctx.fillText(`${this.textContent}`, _x, _y);
        this.textArr.push({
          x: _x,
          y: _y,
          textContent: this.textContent,
          color: this.drawColor,
          fontSize: this.fontSize,
        });
        if (!isFromSocket) {
          this.textArrSocket = {
            index: Math.random(),
            value: this.textArr,
          };
        }
      } else if (this.drawStatus === 5) {
        this.triangle.x = _x;
        this.triangle.y = _y;
      } else if (this.drawStatus === 6) {
        this.moveEraserPoint(e.offsetX, e.offsetY);
        this.drawEraser(this.eraserX, this.eraserY, this.eraserWidth);
      }
    },

    // 鼠标移动
    handleMouseMove(e) {
      if (this.lock) {
        if (this.drawStatus === 1) {
          this.rect.width = Math.abs(this.rect.x - e.offsetX);
          this.rect.height = Math.abs(this.rect.y - e.offsetY);
          if (this.rect.x > e.offsetX) {
            this.rect.realX = e.offsetX;
          } else {
            this.rect.realX = this.rect.x;
          }
          if (this.rect.y > e.offsetY) {
            this.rect.realY = e.offsetY;
          } else {
            this.rect.realY = this.rect.y;
          }
          this.clear();
          this.redrawAll();
          this.drawRect(
            this.rect.realX,
            this.rect.realY,
            this.rect.width,
            this.rect.height,
            0,
            this.drawColor,
            2
          );
        } else if (this.drawStatus === 2) {
          let pointX, pointY;
          if (this.storage.x > e.offsetX) {
            pointX = this.storage.x - Math.abs(this.storage.x - e.offsetX) / 2;
          } else {
            pointX = Math.abs(this.storage.x - e.offsetX) / 2 + this.storage.x;
          }
          if (this.storage.y > e.offsetY) {
            pointY = this.storage.y - Math.abs(this.storage.y - e.offsetY) / 2;
          } else {
            pointY = Math.abs(this.storage.y - e.offsetY) / 2 + this.storage.y;
          }
          let lineX = Math.abs(this.storage.x - e.offsetX) / 2;
          let lineY = Math.abs(this.storage.y - e.offsetY) / 2;
          this.clear();
          this.redrawAll();
          this.drawEllipse(pointX, pointY, lineX, lineY, 2, this.drawColor);
        } else if (this.drawStatus === 3) {
          this.movePoint(e.offsetX, e.offsetY, true);
          this.drawPoint(
            this.lineX,
            this.lineY,
            this.clickDrag,
            this.lineWidth,
            this.drawColor
          );
        } else if (this.drawStatus === 5) {
          let topPoint, leftPoint, rightPoint;
          if (e.offsetX > this.triangle.x && e.offsetY > this.triangle.y) {
            const topX = e.offsetX;
            const topY = this.triangle.y;
            topPoint = {
              x: topX,
              y: topY,
            };
            const leftX = this.triangle.x;
            const leftY = e.offsetY;
            leftPoint = {
              x: leftX,
              y: leftY,
            };
            const rightX = e.offsetX + (e.offsetX - this.triangle.x);
            const rightY = e.offsetY;
            rightPoint = {
              x: rightX,
              y: rightY,
            };
          } else {
            console.log("反向");
            const topX = this.triangle.x;
            const topY = this.triangle.y;
            topPoint = {
              x: topX,
              y: topY,
            };
            const leftX = e.offsetX;
            const leftY = e.offsetY;
            leftPoint = {
              x: leftX,
              y: leftY,
            };
            const rightX = 2 * this.triangle.x - e.offsetX;
            const rightY = e.offsetY;
            rightPoint = {
              x: rightX,
              y: rightY,
            };
          }
          this.trianglePoint = { topPoint, leftPoint, rightPoint };
          this.clear();
          this.redrawAll();
          this.drawTriangle(topPoint, leftPoint, rightPoint, this.drawColor);
        } else if (this.drawStatus === 6) {
          this.moveEraserPoint(e.offsetX, e.offsetY);
          this.drawEraser(this.eraserX, this.eraserY, this.eraserWidth);
        }
      }
    },

    // 鼠标升起
    handleMouseUp(e, isFromSocket = false) {
      this.lineArrValue = false;
      if (this.drawStatus === 1) {
        this.rectArr.push({
          realX: this.rect.realX,
          realY: this.rect.realY,
          width: this.rect.width,
          height: this.rect.height,
          radius: 0,
          color: this.drawColor,
          lineWidth: 2,
        });
        if (!isFromSocket) {
          this.rectArrSocket = {
            index: Math.random(),
            value: this.rectArr,
          };
        }
        this.rect = {};
      } else if (this.drawStatus === 2) {
        let pointX, pointY;
        if (this.storage.x > e.offsetX) {
          pointX = this.storage.x - Math.abs(this.storage.x - e.offsetX) / 2;
        } else {
          pointX = Math.abs(this.storage.x - e.offsetX) / 2 + this.storage.x;
        }
        if (this.storage.y > e.offsetY) {
          pointY = this.storage.y - Math.abs(this.storage.y - e.offsetY) / 2;
        } else {
          pointY = Math.abs(this.storage.y - e.offsetY) / 2 + this.storage.y;
        }
        let lineX = Math.abs(this.storage.x - e.offsetX) / 2;
        let lineY = Math.abs(this.storage.y - e.offsetY) / 2;
        this.circleArr.push({
          x: pointX,
          y: pointY,
          a: lineX,
          b: lineY,
          color: this.drawColor,
          lineWidth: 2,
        });
        if (!isFromSocket) {
          this.circleArrSocket = {
            index: Math.random(),
            value: this.circleArr,
          };
        }
        this.storage = {};
      } else if (this.drawStatus === 3) {
        this.lineArr.push({
          x: this.lineX,
          y: this.lineY,
          clickDrag: this.clickDrag,
          lineWidth: this.lineWidth,
          color: this.drawColor,
        });
        if (!isFromSocket) {
          this.lineArrSocket = {
            index: Math.random(),
            value: this.lineArr,
          };
        }
        this.lineX = [];
        this.lineY = [];
        this.clickDrag = [];
      } else if (this.drawStatus === 5) {
        this.triangleArr.push({
          ...this.trianglePoint,
          triangleColor: this.drawColor,
        });
        if (!isFromSocket) {
          this.triangleArrSocket = {
            index: Math.random(),
            value: this.triangleArr,
          };
        }
        this.trianglePoint = {};
      } else if (this.drawStatus === 6) {
        this.eraserArr.push({
          x: this.eraserX,
          y: this.eraserY,
          eraserWidth: this.eraserWidth,
        });
        if (!isFromSocket) {
          this.eraserArrSocket = {
            index: Math.random(),
            value: this.eraserArr,
          };
        }
        this.eraserX = [];
        this.eraserY = [];
      }

      const preData = this.ctx.getImageData(
        0,
        0,
        this.canvasWidth,
        this.canvasHeight
      );
      if (!this.nextDrawAry.length) {
        // 当前绘图表面进栈
        // console.log("无下一张图");
        this.middleAry.push(preData);
      } else {
        // console.log("有下一张图");
        this.middleAry = [];
        this.middleAry = this.middleAry.concat(this.preDrawAry);
        this.middleAry.push(preData);
        this.nextDrawAry = [];
      }

      this.lock = false;
    },
    // 清除画布
    clear() {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight); //清除画布，左上角为起点
    },

    // 连点
    movePoint(x, y) {
      this.lineX.push(x);
      this.lineY.push(y);
      this.clickDrag.push(y);
    },
    // 橡皮擦
    moveEraserPoint(x, y) {
      this.eraserX.push(x);
      this.eraserY.push(y);
    },
    // 使用橡皮擦
    drawEraser(x, y, eraserWidth) {
      for (let i = 0; i < x.length; i++) {
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.arc(x[i], y[i], eraserWidth / 2, 0, Math.PI * 2);
        this.ctx.clip();
        this.ctx.clearRect(
          x[i] - eraserWidth / 2,
          y[i] - eraserWidth / 2,
          eraserWidth,
          eraserWidth
        );
        this.ctx.restore();
      }
    },
    // 画矩形
    createRect(x, y, width, height, radius, color, type, lineWidth) {
      this.ctx.beginPath();
      this.ctx.moveTo(x, y + radius);
      this.ctx.lineTo(x, y + height - radius);
      this.ctx.quadraticCurveTo(x, y + height, x + radius, y + height);
      this.ctx.lineTo(x + width - radius, y + height);
      this.ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width,
        y + height - radius
      );
      this.ctx.lineTo(x + width, y + radius);
      this.ctx.quadraticCurveTo(x + width, y, x + width - radius, y);
      this.ctx.lineTo(x + radius, y);
      this.ctx.quadraticCurveTo(x, y, x, y + radius);
      this.ctx[type + "Style"] = color;
      this.ctx.lineWidth = lineWidth;
      this.ctx.closePath();
      this.ctx[type]();
    },

    // 画矩形
    drawRect(realX, realY, width, height, radius, color, lineWidth) {
      this.createRect(
        realX,
        realY,
        width,
        height,
        radius,
        color,
        "stroke",
        lineWidth
      );
    },
    // 画圆形
    drawEllipse(x, y, a, b, lineWidth, color) {
      this.ctx.beginPath();
      // this.ctx.shadowBlur = 2;
      // this.ctx.shadowColor = color;
      this.ctx.ellipse(x, y, a, b, 0, 0, 2 * Math.PI);
      this.ctx.lineWidth = lineWidth;
      this.ctx.fillStyle = "rgba(0,0,0,0)";
      this.ctx.strokeStyle = color;
      this.ctx.fill();
      this.ctx.stroke();
    },
    // 画三角形
    drawTriangle(top, left, right, color) {
      this.ctx.beginPath();
      this.ctx.moveTo(top.x, top.y);
      this.ctx.lineTo(left.x, left.y);
      this.ctx.lineTo(right.x, right.y);
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = color;
      this.ctx.closePath();
      this.ctx.stroke();
    },

    // 画线
    drawPoint(x, y, clickDrag, lineWidth, color) {
      for (
        let i = 0;
        i < x.length;
        i++ //循环数组
      ) {
        this.ctx.beginPath();

        if (clickDrag[i] && i) {
          this.ctx.moveTo(x[i - 1], y[i - 1]);
        } else {
          this.ctx.moveTo(x[i] - 1, y[i]);
        }
        this.ctx.lineWidth = lineWidth;
        this.ctx.strokeStyle = color;
        this.ctx.lineTo(x[i], y[i]);
        this.ctx.closePath();
        this.ctx.stroke();
      }
    },
    redrawAll() {
      if (this.rectArr && this.rectArr.length > 0) {
        this.rectArr.forEach((val) => {
          this.drawRect(
            val.realX,
            val.realY,
            val.width,
            val.height,
            val.radius,
            val.color,
            val.lineWidth
          );
        });
      }
      if (this.circleArr && this.circleArr.length > 0) {
        this.circleArr.forEach((val) => {
          this.drawEllipse(
            val.x,
            val.y,
            val.a,
            val.b,
            val.lineWidth,
            val.color
          );
        });
      }
      if (this.lineArr && this.lineArr.length > 0) {
        this.lineArrValue = false;
        this.lineArr.forEach((val) => {
          this.drawPoint(val.x, val.y, val.clickDrag, val.lineWidth, val.color);
        });
      }

      if (this.triangleArr && this.triangleArr.length > 0) {
        this.triangleArr.forEach((val) => {
          this.drawTriangle(
            val.topPoint,
            val.leftPoint,
            val.rightPoint,
            val.triangleColor
          );
        });
      }

      if (this.textArr && this.textArr.length > 0) {
        this.textArr.forEach((val) => {
          this.ctx.fillStyle = val.color;
          this.ctx.font = `${val.fontSize}px bold 黑体`;
          this.ctx.fillText(`${val.textContent}`, val.x, val.y);
        });
      }
      if (this.eraserArr && this.eraserArr.length > 0) {
        this.eraserArr.forEach((val) => {
          this.drawEraser(val.x, val.y, val.eraserWidth);
        });
      }
    },

    // 控制前进 后退  清除画布
    controlCanvas(action) {
      console.log(action);
      const t = this;
      switch (action) {
        case "prev":
          if (t.preDrawAry.length) {
            const popData = t.preDrawAry.pop();
            const midData = t.middleAry[t.preDrawAry.length + 1];
            t.nextDrawAry.push(midData);
            t.ctx.putImageData(popData, 0, 0);
          }
          break;
        case "next":
          if (t.nextDrawAry.length) {
            const popData = t.nextDrawAry.pop();
            const midData =
              t.middleAry[t.middleAry.length - t.nextDrawAry.length - 2];
            t.preDrawAry.push(midData);
            t.ctx.putImageData(popData, 0, 0);
          }
          break;
        case "clear":
          t.ctx.clearRect(0, 0, t.canvasWidth, t.canvasHeight);
          t.preDrawAry = [];
          t.nextDrawAry = [];
          t.middleAry = [t.middleAry[0]];
          t.lineArr = [];
          t.circleArr = [];
          t.rectArr = [];
          t.textArr = [];
          t.triangleArr = [];
          this.imageData = [];
          break;
      }
    },

    textChange(e) {
      this.textContent = e.target.value;
      this.drawStatus = 4;
      this.isShowText = false;
    },
  },
};
</script>

<style scoped lang="scss">
.customScrollbar-box {
  width: 100%;
  height: 100%;
  z-index: 9999;
  line-height: 1;
}
.wrap {
  width: 100%;
  height: 100%;
  position: relative;
}
#canvas-1 {
  cursor: url("../../../assets/img/02-Toolbar/cursor-brush.svg"), crosshair;
  // cursor: crosshair;
  // position: absolute;
  // top: 0;
  // left: 0;
  /*设置所在层数，在上层*/
  // z-index: 9999;
}
#canvas-1.eraserStyle {
  cursor: url("../../../assets/img/02-Toolbar/tool-eraser-active.svg"),
    crosshair;
}
.text-input {
  position: absolute;
  font-size: 2rem;
}
</style>