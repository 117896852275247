// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/02-Toolbar/cursor-brush.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/02-Toolbar/tool-eraser-active.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.customScrollbar-box[data-v-2b281dea] {\n  width: 100%;\n  height: 100%;\n  z-index: 9999;\n  line-height: 1;\n}\n.wrap[data-v-2b281dea] {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n#canvas-1[data-v-2b281dea] {\n  cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), crosshair;\n  /*设置所在层数，在上层*/\n}\n#canvas-1.eraserStyle[data-v-2b281dea] {\n  cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "), crosshair;\n}\n.text-input[data-v-2b281dea] {\n  position: absolute;\n  font-size: 2rem;\n}\n", ""]);
// Exports
module.exports = exports;
